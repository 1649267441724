/* CSS FONT */
@import url("https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap");
/* CSS FONT */

/* CSS RESETS */
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  background-color: #333333;
  font-family: "Cardo", serif;
  font-weight: 400;
  font-style: normal;
}
html {
  scroll-behavior: smooth;
}
h1,
h2,
h3,
p,
ul {
  margin-top: 0;
}
img {
  max-width: 100%;
}
.main-color {
  color: #ecda7d;
}
ul {
  padding-left: 0;
  list-style-type: none;
}
/* CSS RESETS */

/* HEADER */
.logo {
  width: 220px;
  height: 260px;
}
/* HEADER */
/* GENERAL STYLES */
.main-border {
  border: 2px solid #ecda7d;
  border-radius: 12px;
}
.angle-transition {
  transition: all 0.3s ease;
}
.angle-transition.make-transition {
  transform: rotate(180deg);
}
.cursor-pointer {
  cursor: pointer;
}
/* GENERAL STYLES */

.pos-absolute {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
}

.pos-fixed-bottom{
  position: absolute; /* or fixed */
  top: 220px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.border-bottom-custom {
  border-bottom: 1px solid #ecda7d;
}
